<template>
  <div class="setting">
    <Nav navType="fun_text" textCon="账户设置">
      <template v-slot:rightText>
        <span class="iconfont icongengduo" style="color:#000;font-size:24px"></span>
      </template>
    </Nav>
    <div class="info" @click="gotoUserInfo">
        <img src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1409348223,2430771730&fm=26&gp=0.jpg" alt="">
        <div class="middle">
          <div class="name">Mis Emma（默认用户名/微信名/手机号码）</div>
          <div class="account">账号：123456789</div>
        </div>
        <div class="right">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
    </div>
    <div class="content">
      <div class="item" @click="gotoRealName">
        <div class="label">实名认证</div>
        <div class="value">已实名认证/未实名认证</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item" @click="gotoAccountSafety">
        <div class="label">账户安全</div>
        <div class="value">修改密码/修改手机号码</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item">
        <div class="label">我的邀请码</div>
        <div class="value">随时随地邀请好友拿佣金</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item" @click="gotoDealPassword">
        <div class="label">交易密码</div>
        <div class="value">交易密码设置/修改</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item">
        <div class="label">关联账号</div>
        <div class="value">可使用微信/QQ/支付宝快速登录</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="item">
        <div class="label">消息设置</div>
        <div class="value">开启/关闭消息</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item">
        <div class="label">隐私设置</div>
        <div class="value"></div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item">
        <div class="label">用户协议</div>
        <div class="value"></div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item" @click="clearCache">
        <div class="label">清除缓存</div>
        <div class="value">{{cacheNum}}M</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item">
        <div class="label">关于我们</div>
        <div class="value">V1.1.12</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
    </div>
    <div class="button" @click="logOut = true">退出当前帐号</div>
    <!-- 清楚缓存弹窗 -->
    <Popup class="clear_cache" v-model="show_clear_cache">
      <div class="text">确定清楚本地缓存?</div>
      <div class="button">
        <div class="left" @click="show_clear_cache = false">取消</div>
        <div class="right" @click="clearConfirm">确定</div>
      </div>
    </Popup>
    <!-- 退出登录 -->
    <Popup class="clear_cache" v-model="logOut">
      <div class="text">确认退出?</div>
      <div class="button">
        <div class="left" @click="logOut = false">取消</div>
        <div class="right" @click="confirmOut">确定</div>
      </div>
    </Popup>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import { Popup, Toast } from "vant";
export default {
  data() {
    return {
      cacheNum: 38.63,
      show_clear_cache: false,
      logOut: false
    };
  },
  methods:{
    confirmOut() {
      localStorage.removeItem('access_token');
      Toast("退出成功")
      this.$router.push({
        path: "/home"
      })
    },
    gotoRealName(){
      sessionStorage.setItem('verifyType', "realName");
      this.$router.push('/userSafety')
    },
    gotoDealPassword(){
      sessionStorage.setItem('verifyType', "dealPassword");
      this.$router.push('/userSafety')
    },
    gotoAccountSafety(){
      this.$router.push('/accountsafety')
    },
    clearCache(){
      console.log('清除缓存');
      this.show_clear_cache = true;
    },
    clearConfirm(){
      this.show_clear_cache = false;
      this.cacheNum = 0;
      this.$toast('清除成功');
    },
    gotoUserInfo(){
      this.$router.push('/userinfo')
    }
  },
  components: {
    Nav,
    Popup
  }
};
</script>
<style lang="scss" scoped>
.setting {
  width: 100%;
  height: 100vh;
  padding-top: 45px;
  background-color: #f4f4f4;
  font-family:PingFang SC;
  padding-bottom: 1px;
  min-height: 100vh;
  position: fixed;
  .info {
    width: 100%;
    height: 70px;
    margin: 8px 0;
    background-color: #fff;
    padding: 12px 12px;
    display: flex;
    img{
      width:46px;
      height: 46px;
      border-radius: 23px;
      margin-right: 8px;
    }
    .middle{
      flex:1;
      padding-top: 6px;
      .name{
        font-size: 14px;
        color:#333;
        margin-bottom:7px;
      }
      .account{
        font-size: 11px;
        color: #999;
      }
    }
    .right{
      width:7px;
      line-height: 46px;
    }
  }
  .content{
    width:100%;
    padding:0 12px;
    background-color: #fff;
    margin-bottom: 8px;
    .item{
      display: flex;
      height: 45px;
      line-height: 45px;
      border-top: 1px solid #f4f4f4;
      .label{
        color:#333;
        font-size: 14px;
      }
      .value{
        color:#999;
        font-size: 13px;
        text-align: right;
        flex: 1;
      }
      .arrow{
        width:7px;
        margin-left: 3px;
      }
    }
    .item:first-child{
      border:none;
    }
  }
  .button{
    width:100%;
    height: 45px;
    margin-top: 12px;
    text-align: center;
    color:#333;
    font-size: 16px;
    font-weight:500;
    background-color: #fff;
    line-height: 45px;
  }
  .clear_cache{
    width:63%;
    height: 85px;
    text-align: center;
    border-radius: 5px;
    .text{
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      color:#333;
    }
    .button{
      height: 40px;
      border-top: 1px solid #f4f4f4;
      line-height: 40px;
      font-size: 15px;
      display: flex;
      margin:0;
      .left{
        color:#999;
        border-right: 1px solid #f4f4f4;
        flex:1;
      }
      .right{
        flex:1;
        color:#ff8000;
      }
    }
  }
}
</style>
